import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static values = {
    url: String,
    triggerOnVisible: { type: Boolean, default: false },
    replaceOuterHtml: { type: Boolean, default: false }
  };
  hasUrlValue!: boolean;
  urlValue!: string;

  triggerOnVisibleValue!: boolean;
  replaceOuterHtmlValue!: boolean;
  fetched: boolean = false;

  connect() {
    useIntersection(this);
    if (!this.hasUrlValue) {
      console.error('[content-loader] You need to pass an url to fetch the remote content.');
      return;
    }

    if (!this.triggerOnVisibleValue) {
      this.fetchContent();
    }
  }
  appear(_entry: Element) {
    this.fetchContent();
  }

  fetchContent() {
    if (this.fetched) {
      return;
    }
    this.fetched = true;
    fetch(this.urlValue, {
      headers: new Headers({
        'x-content-loader-request': 'true'
      })
    })
      .then((response) => response.text())
      .then((html) => {
        if (this.replaceOuterHtmlValue) {
          this.element.outerHTML = html;
        } else {
          this.element.innerHTML = html;
        }
      });
  }
}
