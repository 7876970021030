import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    data: String,
    mimeType: String
  };
  dataValue!: string;
  mimeTypeValue!: string;

  connect() {
    let dataPretty = this.dataValue;
    if (this.mimeTypeValue == 'application/json') {
      // pretty print it
      dataPretty = JSON.stringify(JSON.parse(this.dataValue), null, 2);
    }
    const anchor = <HTMLAnchorElement>this.element;
    const data = new Blob([dataPretty], { type: this.mimeTypeValue || 'text/plain' });
    anchor.href = window.URL.createObjectURL(data);
  }
}
