import { Controller } from '@hotwired/stimulus';

import { tabbable } from 'tabbable';
// @ts-expect-error no type definition exists
import Dropdown from '../select/dropdown.js';

export default class extends Controller {
  static values = {
    containerId: String
  };
  containerIdValue!: string;
  dropdown!: Dropdown;

  open: boolean = false;

  connect() {
    this.dropdown = this.createDropdown();

    const mutationObserver = new MutationObserver((_mutationList: MutationRecord[], _observer: MutationObserver) => {
      if (this.dropdown && !document.contains(this.dropdown.header)) {
        this.dropdown = this.createDropdown();

        // keep dropdown open if it was open before
        // this is necessary because the dropdown is recreated by blur events
        if (this.open) {
          this.dropdown.open();
        }
      }
    });
    mutationObserver.observe(this.element, { attributes: true, childList: false, subtree: false });

    window.addEventListener('beforeunload', (_event) => {
      try {
        mutationObserver.disconnect();
      } catch (e) {
        // empty on purpose, nothing to handle here
      }
    });
  }

  private createDropdown(): Dropdown {
    if (this.element.hasAttribute('disabled') && this.element.getAttribute('disabled') == 'true') {
      return;
    }
    const tabIndex = this.element.getAttribute('tabindex');
    return Dropdown(<HTMLElement>this.element, {
      autocomplete: true,
      allowEmpty: false,
      dropdownClass: this.element.id,
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
      onchange: (_el: any, _dd: any, _ov: any, nv: string) => {
        const select = <HTMLSelectElement>this.element;
        select.value = nv;

        this.open = false;

        if (document.getElementById(this.containerIdValue) != null) {
          // @ts-ignore
          const tabbableElements = tabbable(document.getElementById(this.containerIdValue));
          const number = tabbableElements.findIndex((element) => element.classList.contains(this.element.id));

          select.blur();
          tabbableElements[number + 1].focus();
          // tabbableElements[number + 1].blur();
        }

        this.dispatch('trigger-live-form-update', {});
        window.dispatchEvent(new Event('jsuites-select-change'));
      },
      onopen: () => {
        this.open = true;
      },
      onclose: () => {
        this.open = false;
      },
      onload: (element: any, instance: any, data: any, currentValue: string) => {
        const input = element.querySelector('.jdropdown-container-header > .jdropdown-header');
        if (tabIndex != null) {
          input.setAttribute('tabindex', tabIndex);
        }
      }
    });
  }
}
