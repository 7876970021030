import { ActionEvent, Controller } from '@hotwired/stimulus';

/**
 * Allows to select all / none of a selection of checkboxes.
 *
 * Usage:
 * <div data-controller="checkbox-select">
 *     <a data-action="checkbox-select#selectAll">Select all</a>
 *     <a data-action="checkbox-select#selectNone">Select none</a>
 *     <a data-action="checkbox-select#selectToggle">Select all/none</a>
 *     <input data-checkbox-select-target="checkbox" type="checkbox" name="checkbox1">
 *     <input data-checkbox-select-target="checkbox" type="checkbox" name="checkbox2">
 * </div>
 *
 * To prevent the change event from being sent to the targetCheckbox
 * (e.g. if another controller is involved), the parameter "preventEvent" can be set. The default is false.
 *
 * Usage:
 * <a data-action="checkbox-select#selectAll" data-action-prevent-event-param="true">Select all</a>
 *
 */
export default class extends Controller {
  static targets = ['checkbox'];

  private checkboxTargets!: HTMLInputElement[];

  connect() {}

  selectAll(event: ActionEvent) {
    this.checkboxTargets.forEach((targetCheckbox) => {
      targetCheckbox.checked = true;
      if (!event.params.preventEvent) {
        targetCheckbox.dispatchEvent(new Event('change'));
      }
    });
    event.preventDefault();
  }

  selectNone(event: ActionEvent) {
    this.checkboxTargets.forEach((targetCheckbox) => {
      targetCheckbox.checked = false;
      if (!event.params.preventEvent) {
        targetCheckbox.dispatchEvent(new Event('change'));
      }
    });
    event.preventDefault();
  }

  selectToggle(event: ActionEvent) {
    this.checkboxTargets.forEach((targetCheckbox) => {
      targetCheckbox.checked = (<HTMLInputElement>event.target).checked;
      if (!event.params.preventEvent) {
        targetCheckbox.dispatchEvent(new Event('change'));
      }
    });
    event.preventDefault();
  }
}
