import { Controller } from '@hotwired/stimulus';
import { createSection, createMedium, createPort, showLinkTools, createLeftPorts, createRightPorts } from './multi-stage-helper';
import { GraphicPort, Medium, Section } from './multi-stage-interfaces';
import { shapes } from '@joint/core';
import Rectangle = shapes.standard.Rectangle;
import Link = shapes.standard.Link;

export default class extends Controller {
  static values = {
    interactive: Boolean
  };
  joint: any;
  namespace: any;
  graph: any;
  paper!: any;
  rect!: any;
  form!: any;
  interactiveValue!: boolean;

  async connect() {
    this.joint = await import(/* webpackChunkName: "joint-core" */ '@joint/core');
    this.form = document.forms[0];
    this.namespace = this.joint.shapes;
    this.graph = new this.joint.dia.Graph({}, { cellNamespace: this.namespace });
    this.paper = this.createPaper();

    if (!this.interactiveValue) {
      this.paper.setInteractivity(false);
    }

    await this.initializeGraphics();
  }

  disconnect() {
    super.disconnect();
    this.graph.clear();
    this.paper.remove();
    // alert('controller disconnected multi');
  }

  private async initializeGraphics() {
    const initialData = await this.getInitData();
    const newArr: Rectangle[] = [];
    initialData.forEach((section: any) => {
      const leftPorts = createLeftPorts();
      const rightPorts = createRightPorts();
      newArr.push(createSection(section, leftPorts, rightPorts, this.paper.options.height, this.paper.options.width));
    });

    let xVal = 0;
    newArr.forEach((rect, index) => {
      const section = initialData[index];
      rect.prop('id', section.id);
      rect.translate(xVal, 0);
      section.portList.forEach((port: GraphicPort) => {
        const color: any = port.isHotSide ? '#FF0000' : '#0000FF';
        rect.addPort({
          id: port.id,
          group: port.side,
          attrs: {
            label: { text: port.name },
            portBody: { fill: color }
          }
        });
      });
      this.setRectData(rect, this.paper);
      xVal += 300;
    });

    xVal = 0;
    let hotXVal = 0;
    let coldXVal = 0;
    let yVal = this.paper.options.height * 0.3 + 200;
    const links: Link[] = [];
    const media: Rectangle[] = [];
    initialData.forEach((section: any, sectionIndex: number) => {
      let sec: Rectangle = newArr[sectionIndex];
      if (sectionIndex > 0) {
        // @ts-ignore
        hotXVal = sec.get('position').x - 100;
        // @ts-ignore
        coldXVal = sec.get('position').x - 100;
      }

      section.fluidList.forEach((fluid: Medium) => {
        const port = createPort('', fluid.port.id, fluid.port.side, fluid.port.side == 'left' ? -12 : 0);
        const medium = createMedium(fluid, port);
        medium.prop('sectionId', initialData.id);
        const color: any = fluid.port.isHotSide ? '#FF0000' : '#0000FF';
        medium.addPort({
          id: fluid.port.id,
          group: fluid.port.side,
          attrs: {
            label: { text: fluid.port.name },
            portBody: { fill: color }
          }
        });
        if (fluid.isHotSide) {
          medium.prop('xAxisValue', hotXVal);
          hotXVal += 140;
        } else {
          medium.prop('xAxisValue', coldXVal);
          coldXVal += 140;
        }

        media.push(medium);
      });
      section.linkList.forEach((link: any) => {
        links.push(this.addLink(link.fromParentId, link.fromPortId, link.toParentId, link.toPortId));
      });
    });

    media.forEach((rect) => {
      if (rect.attributes.id.includes('hot')) {
        yVal = this.paper.options.height * 0.3 - 250;
        rect.translate(rect.prop('xAxisValue'), yVal);
      } else {
        yVal = this.paper.options.height * 0.3 + 200;
        rect.translate(rect.prop('xAxisValue'), yVal);
      }
      rect.addTo(this.graph);
    });

    links.forEach((link) => {
      link.addTo(this.graph);
    });
  }

  private async updatePheMultiStage() {
    const sec: Section = await this.getSectionDataForUpdate();
    this.updateSectionData(sec);
  }

  // rect: shapes.standard.Rectangle, paper: dia.Paper
  private setRectData(rect: any, paper: any) {
    rect.addTo(this.graph);

    paper.on('element:pointerdblclick', (elementView: any) => {
      this.resetAll(paper);
      const currentElement = elementView.model;
      this.activate(currentElement);
      currentElement.attr('body/stroke', 'orange');
    });

    // Register events
    paper.on('link:mouseenter', (linkView: any) => {
      showLinkTools(linkView);
    });

    paper.on('link:mouseleave', (linkView: any) => {
      linkView.removeTools();
    });
  }

  addLink(sourceId: any, sourcePortId: any, targetId: any, targetPortId: any) {
    return new this.joint.shapes.standard.Link({
      source: {
        id: sourceId,
        port: sourcePortId
      },
      target: {
        id: targetId,
        port: targetPortId
      }
    });
  }

  createPaper() {
    return new this.joint.dia.Paper({
      el: <HTMLElement>this.element!,
      model: this.graph,
      width: this.element.clientWidth * 2,
      gridSize: 1,
      cellViewNamespace: this.namespace,
      restrictTranslate: true,
      linkPinning: false, // Prevent link being dropped in blank paper area
      defaultLink: () =>
        new this.joint.shapes.standard.Link({
          attrs: {
            wrapper: {
              cursor: 'default'
            }
          }
        })
    });
  }

  private resetAll(paper: any) {
    const elements = paper.model.getElements();
    for (let i = 0, ii = elements.length; i < ii; i++) {
      const currentElement = elements[i];
      currentElement.attr('body/stroke', 'black');
    }
  }

  private async rebuild() {
    this.graph.clear();
    //this.paper.remove();
    // this.form.removeEventListener('update-phe-multistage', () => this.updatePheMultiStage());
    // this.form.removeEventListener('rebuild-phe-multistage', () => this.rebuild());
    await this.initializeGraphics();
  }

  private activate(currentElement: any) {
    const id = currentElement.id;

    fetch('/phe/multi_stage/section', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify(id)
    }).then(() => this.dispatch('trigger-live-form-update', {}));
  }

  private getInitData(): any {
    return fetch('/phe/multi_stage/initialize', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json;charset=utf-8' }
    })
      .then((response) => {
        return response.json();
      })
      .then((dataObj) => {
        return dataObj;
      });
  }

  private getSectionDataForUpdate(): any {
    return fetch('/phe/multi_stage/section_data', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json;charset=utf-8' }
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });
  }

  private updateSectionData(sectionUpdate: Section) {
    const section: Rectangle = this.graph.getCell(sectionUpdate.id);
    section.attr('hotInTemp/text', Number(sectionUpdate.hotInTemp).toFixed(2));
    section.attr('hotOutTemp/text', Number(sectionUpdate.hotOutTemp).toFixed(2));
    section.attr('coldInTemp/text', Number(sectionUpdate.coldInTemp).toFixed(2));
    section.attr('coldOutTemp/text', Number(sectionUpdate.coldOutTemp).toFixed(2));
    section.attr('hotPressureDrop/text', Number(sectionUpdate.hotPressureDrop).toFixed(3) + ' kPa');
    section.attr('coldPressureDrop/text', Number(sectionUpdate.coldPressureDrop).toFixed(3) + ' kPa');
    section.attr('hotPasses/text', sectionUpdate.hotPasses + ' / ' + sectionUpdate.hotGapsPerPass);
    //section.attr('coldPasses/text', sectionUpdate.coldPasses + ' / ' + sectionUpdate.coldGapsPerPass);
  }
}
