import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['focusfield'];

  private focusfieldTarget!: HTMLInputElement;
  private hasFocusfieldTarget!: boolean;

  connect() {
    const form = <HTMLFormElement>this.element;
    if (!form.hasAttribute('form-invalid')) {
      if (this.hasFocusfieldTarget) {
        this.focusfieldTarget.focus();
      }
    }
  }
}
