import { Section, Medium } from './multi-stage-interfaces';
import { dia, shapes, linkTools } from '@joint/core';
import PortGroup = dia.Element.PortGroup;
import CellViewGeneric = dia.CellViewGeneric;
import Cell = dia.Cell;

export function createSection(section: Section, leftPorts: PortGroup, rightPorts: PortGroup, paperHeight: number, _paperWidth: number) {
  const rect = new shapes.standard.Rectangle({
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'text',
        selector: 'label'
      },
      {
        tagName: 'text',
        selector: 'hotInTemp'
      },
      {
        tagName: 'text',
        selector: 'coldInTemp'
      },
      {
        tagName: 'text',
        selector: 'hotOutTemp'
      },
      {
        tagName: 'text',
        selector: 'coldOutTemp'
      },
      {
        tagName: 'text',
        selector: 'tempUnitTop'
      },
      {
        tagName: 'text',
        selector: 'tempUnitBottom'
      },
      {
        tagName: 'text',
        selector: 'hotPressureDrop'
      },
      {
        tagName: 'text',
        selector: 'coldPressureDrop'
      },
      {
        tagName: 'text',
        selector: 'hotPasses'
      }
      // {
      //     tagName: 'text',
      //     selector: 'coldPasses'
      // }
    ],

    ports: {
      groups: {
        left: leftPorts,
        right: rightPorts
      }
    }
  });
  rect.prop('id', section.id);
  rect.position(100, paperHeight * 0.3);
  rect.resize(150, 150);
  rect.attr({
    body: {
      fill: '#949494'
    },
    label: {
      text: section.name,
      fill: 'white',
      'font-weight': 'bold'
    },
    hotInTemp: {
      text: Number(section.hotInTemp).toFixed(2),
      fill: 'white',
      fontSize: 11,
      x: 'calc(0.01*h)'
    },
    coldInTemp: {
      text: Number(section.coldInTemp).toFixed(2),
      fill: 'white',
      y: 'calc(0.99*h)',
      x: 'calc(0.01*h)',
      fontSize: 11
    },
    hotOutTemp: {
      text: Number(section.hotOutTemp).toFixed(2),
      fill: 'white',
      x: 'calc(0.73*w)',
      fontSize: 11
    },

    coldOutTemp: {
      text: Number(section.coldOutTemp).toFixed(2),
      fill: 'white',
      x: 'calc(0.73*w)',
      y: 'calc(0.99*h)',
      fontSize: 11
    },

    tempUnitTop: {
      text: '°C',
      fill: 'white',
      x: 'calc(0.45*w)',
      fontSize: 11
    },

    tempUnitBottom: {
      text: '°C',
      fill: 'white',
      x: 'calc(0.45*w)',
      y: 'calc(0.99*h)',
      fontSize: 11
    },

    hotPressureDrop: {
      text: Number(section.hotPressureDrop).toFixed(3) + ' kPa',
      fill: 'white',
      x: 'calc(0.35*w)',
      y: 'calc(0.15*h)',
      fontSize: 11
    },
    coldPressureDrop: {
      text: Number(section.coldPressureDrop).toFixed(3) + ' kPa',
      fill: 'white',
      x: 'calc(0.35*w)',
      y: 'calc(0.65*h)',
      fontSize: 11
    },

    hotPasses: {
      text: section.hotPasses + ' / ' + section.hotGapsPerPass,
      fill: 'white',
      x: 'calc(0.42*w)',
      y: 'calc(0.25*h)',
      fontSize: 11
    }

    // coldPasses: {
    //     text: section.coldPasses + ' / ' + section.coldGapsPerPass,
    //     fill: 'white',
    //     x: 'calc(0.42*w)',
    //     y: 'calc(0.75*h)',
    //     fontSize: 11
    // }
  });
  return rect;
}

export function createMedium(medium: Medium, port: PortGroup) {
  const rect = new shapes.standard.Rectangle({
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'text',
        selector: 'label'
      },
      {
        tagName: 'text',
        selector: 'mediumName'
      },
      {
        tagName: 'text',
        selector: 'volumeFlow'
      },
      {
        tagName: 'text',
        selector: 'temp'
      },
      {
        tagName: 'text',
        selector: 'operatingPressure'
      },
      {
        tagName: 'text',
        selector: 'label6'
      },
      {
        tagName: 'text',
        selector: 'label7'
      }
    ],

    ports: {
      groups: {
        left: port,
        right: port
      }
    }
  });
  rect.prop('id', medium.id);
  rect.position(100, 100);
  rect.resize(120, 100);
  rect.attr({
    body: {
      fill: '#949494'
    },
    mediumName: {
      text: medium.name,
      fill: 'white',
      'ref-y': 'calc(0.01*h)',
      'font-weight': 'bold',
      fontSize: 11,
      textWrap: {}
    },
    volumeFlow: {
      text: Number(medium.volumeFlow).toFixed(2) + ' m³/h',
      fill: 'white',
      y: 'calc(0.40*h)',
      fontSize: 11
    },
    temp: {
      text: Number(medium.temp).toFixed(2) + ' °C',
      fill: 'white',
      y: 'calc(0.55*h)',
      fontSize: 11
    },

    operatingPressure: {
      text: Number(medium.operatingPressure).toFixed(2) + ' barg',
      fill: 'white',
      y: 'calc(0.70*h)',
      fontSize: 11
    }

    /*  label6: {
              text: 'CIP: 13.68 m²/h',
              fill: 'white',
              y: 'calc(0.85*h)',
              fontSize: 11
          },

          label7: {
              text: 'CIP 142.000 kPa',
              fill: 'white',
              y: 'calc(0.98*h)',
              fontSize: 11
          }
         */
  });
  return rect;
}

export function createPort(text: string, id: string, portPosition: string, xValue: number): object {
  return {
    position: {
      name: portPosition
    },
    label: {
      position: {
        name: portPosition
      },
      markup: [
        {
          tagName: 'text',
          selector: 'label'
        }
      ]
    },
    attrs: {
      portBody: {
        magnet: true,
        width: 12,
        height: 12,
        x: xValue,
        fill: '#03071E'
      },
      label: {
        text: text,
        fontSize: 11
      }
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'portBody'
      }
    ]
  };
}

export function createButton(): shapes.standard.Rectangle {
  const rect = new shapes.standard.Rectangle();
  rect.prop('id', 3);
  rect.position(100, 200);
  rect.resize(100, 30);

  rect.attr({
    body: {
      fill: '#add8e6'
    },
    label: {
      text: 'Update Data',
      fill: 'white'
    }
  });
  return rect;
}

export function showLinkTools(linkView: CellViewGeneric<Cell>) {
  const tools = new dia.ToolsView({
    tools: [
      new linkTools.Remove({
        distance: '50%',
        markup: [
          {
            tagName: 'circle',
            selector: 'button',
            attributes: {
              r: 7,
              fill: '#f6f6f6',
              stroke: '#ff5148',
              'stroke-width': 2,
              cursor: 'pointer'
            }
          },
          {
            tagName: 'path',
            selector: 'icon',
            attributes: {
              d: 'M -3 -3 3 3 M -3 3 3 -3',
              fill: 'none',
              stroke: '#ff5148',
              'stroke-width': 2,
              'pointer-events': 'none'
            }
          }
        ]
      })
    ]
  });
  linkView.addTools(tools);
}

export function createLeftPorts(): dia.Element.PortGroup {
  return {
    position: {
      name: 'left'
    },
    attrs: {
      portBody: {
        magnet: true,
        width: 12,
        height: 12,
        x: -12,
        fill: '#03071E'
      }
    },
    label: {
      position: {
        name: 'left',
        args: { y: 6 }
      },
      markup: [
        {
          tagName: 'text',
          selector: 'label',
          className: 'label-text'
        }
      ]
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'portBody'
      }
    ]
  };
}

export function createRightPorts(): dia.Element.PortGroup {
  return {
    position: {
      name: 'right'
    },
    attrs: {
      portBody: {
        magnet: true,
        width: 12,
        height: 12,
        x: 0,
        fill: '#03071E'
      }
    },
    label: {
      position: {
        name: 'right',
        args: { y: 6 }
      },
      markup: [
        {
          tagName: 'text',
          selector: 'label',
          className: 'label-text'
        }
      ]
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'portBody'
      }
    ]
  };
}
