import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'deselectOptionButton',
    'applyNewExternalPressureButton',
    'requestInProgressButton',
    'keepRecommendationButton',
    'externalPressureForm',
    'invalidAlert',
    'externalPressureToHighWarning'
  ];
  static values = {
    selectedOption: String,
    unitFormat: String,
    applyButtonTextTemplate: String,
    locale: String,
    recommendedExternalPressure: Number
  };

  deselectOptionButtonTarget!: HTMLButtonElement;
  applyNewExternalPressureButtonTarget!: HTMLButtonElement;
  requestInProgressButtonTarget!: HTMLButtonElement;
  keepRecommendationButtonTarget!: HTMLButtonElement;
  externalPressureFormTarget!: HTMLFormElement;
  externalPressureToHighWarningTarget!: HTMLDivElement;
  invalidAlertTarget!: HTMLDivElement;
  selectedOptionValue!: string;
  unitFormatValue!: string;
  applyButtonTextTemplateValue!: string;
  localeValue!: string;
  recommendedExternalPressureValue!: number;

  abortController!: AbortController;
  requestInProgress = false;

  connect() {
    const numberValue = this.getExternalPressureAsNumber();

    const formattedNumber = new Intl.NumberFormat(this.localeValue, { maximumSignificantDigits: 2 }).format(numberValue);
    this.applyNewExternalPressureButtonTarget.innerText = this.applyButtonTextTemplateValue.replace(
      '#externalPressure',
      `${formattedNumber} ${this.unitFormatValue}`
    );
    // do not show the button by default as it will be the same action as keeping the recommendation
    // this.applyNewExternalPressureButtonTarget.classList.remove("hiddenDiv");
  }

  pressureChanged(e: Event) {
    this.externalPressureToHighWarningTarget.classList.add('hiddenDiv');
    this.checkValidity();
  }
  onDeselectOptionButtonClick(e: Event) {
    // @ts-ignore
    document.getElementById(`option-row-${this.selectedOptionValue.replace('.', '-')}`).click();
  }

  getExternalPressureAsNumber(): number {
    const elementById = document.getElementById('external-pressure-overwrite') as HTMLInputElement;
    const value = elementById.value.replace(',', '.');
    return Number(value) as number;
  }
  checkValidity() {
    let isValid = this.externalPressureFormTarget.checkValidity();

    const numberValue = this.getExternalPressureAsNumber();

    if (isValid) {
      if (isNaN(numberValue)) {
        isValid = false;
      }
      if (isValid) {
        isValid = numberValue >= 0.0;
      }
    }

    if (isValid) {
      const formattedNumber = new Intl.NumberFormat(this.localeValue, { maximumSignificantDigits: 2 }).format(numberValue);
      this.invalidAlertTarget.classList.add('hiddenDiv');
      this.applyNewExternalPressureButtonTarget.classList.remove('hiddenDiv');
      this.applyNewExternalPressureButtonTarget.innerText = this.applyButtonTextTemplateValue.replace(
        '#externalPressure',
        `${formattedNumber} ${this.unitFormatValue}`
      );
    } else {
      this.invalidAlertTarget.classList.remove('hiddenDiv');
      this.applyNewExternalPressureButtonTarget.classList.add('hiddenDiv');
    }

    return isValid;
  }
  async onApplyNewExternalPressureButtonClick(e: Event) {
    e.preventDefault();
    if (this.requestInProgress) {
      return;
    }
    if (this.abortController) {
      this.abortController.abort('Validation reason.');
    }
    this.abortController = new AbortController();

    const isValid = this.checkValidity();

    if (!isValid) {
      return;
    }

    this.dispatch('block');
    this.requestInProgress = true;
    this.hideButtons();

    let formData = new FormData(this.externalPressureFormTarget) as FormData;
    formData.set('externalPressure', this.getExternalPressureAsNumber().toString());
    // @ts-ignore
    const postBody = new URLSearchParams(formData).toString().replace('&_method=patch', '');
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded', 'x-content-loader-request': 'true' };
    const url = this.externalPressureFormTarget.action;
    const response = await fetch(url, {
      body: postBody,
      headers: headers,
      method: 'post',
      signal: this.abortController.signal
    });

    if (!response.ok) {
      this.requestInProgress = false;
      this.showButtons();
      this.externalPressureToHighWarningTarget.classList.remove('hiddenDiv');
      return;
    }
    this.requestInProgress = false;
    this.dispatch('closeModal');
  }

  showButtons() {
    let elementById = document.getElementById('external-pressure-overwrite') as HTMLInputElement;
    elementById.disabled = false;
    this.deselectOptionButtonTarget.classList.remove('hiddenDiv');
    this.applyNewExternalPressureButtonTarget.classList.remove('hiddenDiv');
    this.requestInProgressButtonTarget.classList.add('hiddenDiv');
    this.keepRecommendationButtonTarget.classList.remove('hiddenDiv');
  }

  hideButtons() {
    let elementById = document.getElementById('external-pressure-overwrite') as HTMLInputElement;
    elementById.disabled = true;

    this.deselectOptionButtonTarget.classList.add('hiddenDiv');
    this.applyNewExternalPressureButtonTarget.classList.add('hiddenDiv');
    this.requestInProgressButtonTarget.classList.remove('hiddenDiv');
    this.keepRecommendationButtonTarget.classList.add('hiddenDiv');
  }
}
