import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';

/**
 * Uses SlimSelect to display a multi select as dropdown which can be used within usual live forms with spring form bindings.
 *
 * Following parameters can be provided via usual stimulus convention data properties:
 *
 *  -- placeholderText
 * -- showSearch
 * -- maxValuesShown
 * -- disabled
 *
 * Usage:
 *
 * <@form.labelWrapper label="Test">
 *     <@form.multiSelectLive path="pheSearch.multiSelectTest" collection=hotFluids/>
 *  </@form.labelWrapper>
 *
 *  Usage with spring multi select for :
 *  <@spring.formMultiSelect path collection "class='k-custom-select-with-search'
 *  data-controller='multi-select-live'
 *  data-multi-select-live-placeholder-text-value='${placeholderText}'
 *  data-multi-select-live-show-search-value='${showSearch}'
 *  data-multi-select-live-max-values-shown-value='${maxValuesToShow}'
 *  data-multi-select-live-disabled-value='${disabled}'
 *  data-multi-select-live-max-values-message-value='{number} Elemente selektiert'/>
 *
 *
 */
export default class extends Controller<HTMLSelectElement> {
  static values = {
    showSearch: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholderText: { type: String, default: '' },
    maxValuesShown: { type: Number, default: 3 },
    maxValuesMessage: { type: String, default: '{number} values selected' }
  };
  showSearchValue!: boolean;
  disabledValue!: boolean;
  placeholderTextValue!: string;
  maxValuesShownValue!: number;
  maxValuesMessageValue!: string;

  slimSelect!: SlimSelect;
  open: boolean = false;
  mutationObserver!: MutationObserver;

  connect() {
    this.mutationObserver = new MutationObserver((_mutationList: MutationRecord[], _observer: MutationObserver) => {
      const hasEnabledClass = this.element.classList.contains('ss-enabled');
      if (this.slimSelect) {
        this.slimSelect.destroy();
        _observer.disconnect();
        this.element.classList.remove('ss-enabled');
      }

      if (!hasEnabledClass) {
        this.slimSelect = this.createSlimSelect();

        // keep dropdown open if it was open before
        // this is necessary because the dropdown is recreated by blur events
        if (this.open) {
          this.slimSelect.open();
        }
      }
    });

    this.slimSelect = this.createSlimSelect();
    window.addEventListener('pagehide', (_event) => {
      try {
        if (this.slimSelect) {
          this.slimSelect.destroy();
        }
        if (this.mutationObserver) {
          this.mutationObserver.disconnect();
        }
      } catch (e) {
        // empty on purpose, nothing to handle here
      }
    });
  }

  disconnect() {
    if (this.slimSelect) {
      this.slimSelect.destroy();
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  private createSlimSelect(): SlimSelect {
    const slimSelect = new SlimSelect({
      select: this.element,
      settings: {
        closeOnSelect: false,
        showSearch: this.showSearchValue || false,
        placeholderText: this.placeholderTextValue,
        maxValuesShown: this.maxValuesShownValue,
        disabled: this.disabledValue,
        maxValuesMessage: this.maxValuesMessageValue,
        allowDeselect: true
      },
      events: {
        afterOpen: () => {
          this.open = true;
        },
        afterClose: () => {
          this.open = false;
          this.dispatch('trigger-live-form-update', {});
        },
        afterChange: (newVal) => {
          if (this.open) {
            // Do nothing on purpose
          } else {
            this.element;
            this.dispatch('trigger-live-form-update', {});
          }
        }
      }
    });

    this.element.classList.add('ss-enabled');
    setTimeout(() => {
      this.mutationObserver.observe(this.element, { attributes: true, childList: false, subtree: false });
    }, 100);
    return slimSelect;
  }
}
